<template>
  <v-container fluid class="content-wrapper mb-6">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600">{{ $t("SigninRoomAmenity.title1st") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="initAll()"
        :class="loadingData && 'animate__spining'"
        :disabled="loadingData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row row wrap>
      <v-col cols="12">
        <div class="card card-shadow pa-5">
          <h3 class="title-sm">{{ $t("SigninRoomAmenity.title2nd") }}</h3>
          <div v-if="loadingData">
            <v-skeleton-loader
              type="list-item-two-line, list-item-two-line, list-item-two-line"
            ></v-skeleton-loader>
          </div>
          <div v-if="!loadingData" class="overflow-y-auto" style="max-height: 65vh">
            <template v-for="(item, index) in itemRoomAmenity">
              <v-card outlined :key="index" class="pa-4 mb-4">
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="3">
                    <span class="regular textstyle">{{ item.amenityname }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" lg="9" class="d-flex flex-column">
                    <div>
                      <v-btn
                        @click="changeAllRooms(item)"
                        dense
                        light
                        :class="
                          item.allrooms ? 'btn-allrooms-left-active' : 'btn-allrooms-left'
                        "
                        elevation="0"
                      >
                        <span class="textsyle">
                          {{ $t("SigninRoomAmenity.select_allroom") }}
                        </span>
                      </v-btn>
                      <v-btn
                        @click="changeSomeRooms(item)"
                        dense
                        light
                        :class="
                          item.somerooms
                            ? 'btn-allrooms-right-active'
                            : 'btn-allrooms-right'
                        "
                        elevation="0"
                      >
                        <span class="textsyle">
                          {{ $t("SigninRoomAmenity.select_someroom") }}
                        </span>
                      </v-btn>
                      <v-btn
                        @click="changeNoRoom(item)"
                        dense
                        light
                        :class="
                          item.noroom ? 'btn-allrooms-right-active' : 'btn-allrooms-right'
                        "
                        elevation="0"
                      >
                        <span class="textsyle">
                          {{ $t("SigninRoomAmenity.select_noroom") }}
                        </span>
                      </v-btn>
                    </div>
                    <div
                      :key="componentKey"
                      v-if="item.somerooms"
                      class="d-flex flex-column mt-5"
                    >
                      <span class="regular mb-3 textstyle">{{
                        $t("SigninRoomAmenity.select_location")
                      }}</span>
                      <div
                        v-for="(element, i) in item.rooms"
                        :key="`${item._id}_${element._id}`"
                      >
                        <div class="checkbox-group d-block">
                          <input
                            :id="`${item._id}_${element._id}`"
                            name="rooms"
                            type="checkbox"
                            :value="`${item._id}_${element._id}`"
                            :checked="element.check"
                          />
                          <label :for="`${item._id}_${element._id}`" class="regular">
                            <span>{{ element.room_name }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </div>
          <v-divider v-if="!loadingData"></v-divider>
          <v-row v-if="!loadingData" row wrap class="mt-3">
            <v-col cols="6" sm="6" md="2" lg="2">
              <v-btn
                depressed
                block
                dark
                color="#666ee8"
                style="text-transform: capitalize !important"
                :disabled="!cansaveroomamenity"
                @click="dialogConfirmSave = true"
              >
                <span class="textstyle">
                  {{ $t("SigninRoomAmenity.btn_save") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogConfirmSave"
      persistent
      max-width="360"
      @keydown.esc="dialogConfirmSave = false"
    >
      <v-card>
        <v-card-title
          class="text-center"
          style="font-size: 1rem !important; letter-spacing: normal !important"
          >{{ $t("Alert.doyouwanttosave") }}</v-card-title
        >
        <v-card-actions class="justify-center align-center pb-5">
          <v-btn
            depressed
            color="#e83e8c"
            class="pa-3 mr-3 white--text"
            @click="saveRoomAmenity()"
            >{{ $t("SigninGeneral.btn_save") }}</v-btn
          >
          <v-btn
            color="#e83e8c"
            class="pa-3"
            outlined
            @click="dialogConfirmSave = false"
            >{{ $t("SigninGeneral.btn_cancel") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import _ from "lodash";

export default {
  name: "SigninRoomAmenityScreen",
  metaInfo: {
    title: "Room Amenity",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        // this.initAll();
        next(false);
      }
    } else next();
  },
  data: () => ({
    itemOldRoomAmenity: [],
    itemRoomAmenity: [],
    userToken: "",
    propertyID: "",
    propertyData: null,
    itemsOldAmenity: [],
    loading: false,
    loadingSave: false,
    cansaveroomamenity: false,
    itemsDefaultRoomName: [],
    itemsDefaultAmenity: [],
    itemsSelectAmenity: [],
    itemsRoomName: [],
    addNewAmenity: "",
    value_element: "",
    componentKey: 0,
    dialogConfirmSave: false,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
    self.cansaveroomamenity = true;
  },
  watch: {
    itemsDefaultAmenity() {
      const self = this;
      self.checkSelectAmenity();
    },
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      // console.log('ttt', document.querySelector('.hover_btn'))
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.initPropertyData();
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempAmenity = [];
      var tempRoomName = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log("temp: ", temp);
          if (temp?.room_amenities?.length) {
            tempAmenity = temp?.room_amenities || [];
            self.cansaveroomamenity = true;
          }
          if (temp?.rooms?.length) {
            temp?.rooms?.map((el) => {
              if (el?._id) {
                tempRoomName.push({
                  _id: el._id,
                  roomname: el.room_name,
                  room_name: el.room_name.value,
                  check: false,
                });
              }
            });
            self.cansaveroomamenity = true;
          }
          if (temp?.room_amenities?.length == 0 && temp?.rooms?.length == 0) {
            self.cansaveroomamenity = false;
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.itemsOldAmenity = tempAmenity;
        self.itemsRoomName = tempRoomName;
        self.itemsDefaultRoomName = tempRoomName;
        self.initDefaultAmenity();
      }
    },
    async initDefaultAmenity() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/room_amenity",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          // console.log("room_amenity: ", res.data.result);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsDefaultAmenity = temp;
        self.initRoomDetails();
      }
    },
    initRoomDetails() {
      const self = this;
      var temp = [];
      var tempOld = [];
      var tempRooms = [];
      var tmpDefaultRooms = [];
      self.loadingData = true;
      EventBus.$emit("inProgressing");
      // console.log("itemsOldAmenity");

      self?.itemsDefaultRoomName?.map((ele) => {
        tmpDefaultRooms.push(ele);
      });

      self.itemsDefaultAmenity?.map((select) => {
        tempRooms = [];
        const mappindOldAmenity = self?.itemsOldAmenity?.find(
          (p) =>
            p._id == select?._id ||
            p.room_amenity?._id == select?._id ||
            p.room_amenity == select?._id
        );
        // console.log("sel: ", select, mappindOldAmenity);

        if (mappindOldAmenity?._id) {
          // console.log(mappindOldAmenity);
          self?.itemsDefaultRoomName?.map((ele) => {
            const checkrooms = mappindOldAmenity?.rooms?.find((p) => p._id == ele._id);
            if (checkrooms?._id) {
              tempRooms.push({
                ...ele,
                check: true,
              });
            } else tempRooms.push(ele);
          });

          temp.push({
            _id: mappindOldAmenity?._id,
            active: mappindOldAmenity?.active || false,
            amenityid: mappindOldAmenity?.room_amenity?._id,
            amenityname: mappindOldAmenity?.room_amenity?.value,
            room_amenity: mappindOldAmenity?.room_amenity,
            allrooms:
              mappindOldAmenity?.rooms?.length == self?.itemsDefaultRoomName?.length
                ? true
                : false,
            somerooms:
              mappindOldAmenity?.rooms?.length &&
              mappindOldAmenity?.rooms?.length < self?.itemsDefaultRoomName?.length
                ? true
                : false,
            noroom: mappindOldAmenity?.rooms?.length == 0 ? true : false,
            rooms: tempRooms,
            oldAmenity: true,
          });
          tempOld.push({
            _id: mappindOldAmenity?._id,
            active: mappindOldAmenity?.active || false,
            amenityid: mappindOldAmenity?.room_amenity?._id,
            amenityname: mappindOldAmenity?.room_amenity?.value,
            room_amenity: mappindOldAmenity?.room_amenity,
            allrooms:
              mappindOldAmenity?.rooms?.length == self?.itemsDefaultRoomName?.length
                ? true
                : false,
            somerooms:
              mappindOldAmenity?.rooms?.length &&
              mappindOldAmenity?.rooms?.length < self?.itemsDefaultRoomName?.length
                ? true
                : false,
            noroom: mappindOldAmenity?.rooms?.length == 0 ? true : false,
            rooms: tempRooms,
            oldAmenity: true,
          });
        } else {
          temp.push({
            _id: select?._id,
            active: false,
            amenityid: select?._id,
            amenityname: select?.value,
            room_amenity: select,
            allrooms: false,
            somerooms: false,
            noroom: true,
            rooms: tmpDefaultRooms,
            oldAmenity: false,
          });
          tempOld.push({
            _id: select?._id,
            active: false,
            amenityid: select?._id,
            amenityname: select?.value,
            room_amenity: select,
            allrooms: false,
            somerooms: false,
            noroom: true,
            rooms: tmpDefaultRooms,
            oldAmenity: false,
          });
        }
      });

      if (temp?.length) {
        self.itemOldRoomAmenity = tempOld;
        self.itemRoomAmenity = temp;
        self.loadingData = false;
        EventBus.$emit("endProgress");
        // console.log("temp: ", temp, self.itemsDefaultAmenity);
      } else {
        setTimeout(() => {
          self.loadingData = false;
          EventBus.$emit("endProgress");
        }, 2000);
      }
      self.checkSelectAmenity();
    },
    checkSelectAmenity() {
      const self = this;
      var temp = [];
      self?.itemsDefaultAmenity?.map((el) => {
        const findNewAmenity = self?.itemRoomAmenity?.find(
          (p) => p?.amenityid == el?._id
        );
        // console.log("findNewAmenity: ", findNewAmenity);
        if (!findNewAmenity?._id) {
          temp.push(el);
        }
      });
      self.itemsSelectAmenity = temp;
      // console.log("select: ", self.itemsSelectAmenity);
    },
    toggleRoomsFunc(item) {
      const self = this;
      var temp = [];
      // console.log("item: ", item);
      // console.log(item.allrooms);
      if (item.allrooms) {
        item?.rooms?.map((el) => {
          temp.push({
            ...el,
            check: true,
          });
        });
      } else if (item.somerooms) {
        item?.rooms?.map((el) => {
          temp.push({
            ...el,
            allrooms: false,
            somerooms: true,
            noroom: false,
          });
        });
      } else if (item.noroom) {
        item?.rooms?.map((el) => {
          temp.push({
            ...el,
            allrooms: false,
            somerooms: false,
            noroom: true,
          });
        });
      } else {
        // self.initRoomDetails();
        item?.rooms?.map((el) => {
          temp.push({
            ...el,
            allrooms: false,
          });
        });
      }
      item.rooms = temp;
    },
    changeRoomsFunc(item, element, index) {
      const self = this;
      element.check = !element.check;
      // console.log("check: ", element.check);
    },
    gobackFunc() {
      this.loading = true;
      EventBus.$emit("goBackPage");
    },
    saveRoomAmenity() {
      const self = this;
      var tempRooms = [];
      var tmpArray = [];
      self.loading = true;
      self.cansaveroomamenity = false;
      self.dialogConfirmSave = false;
      // console.log(self?.itemRoomAmenity);
      self.loadingData = true;
      self?.itemRoomAmenity?.map((el, i) => {
        const mappindOldAmenity = self?.itemOldRoomAmenity?.find((p) => p._id == el?._id);
        tempRooms = [];
        if (el.allrooms) {
          el?.rooms?.map((ele) => {
            tempRooms.push(ele._id);
          });
        } else if (el.somerooms) {
          el?.rooms?.map((ele) => {
            const cb = document.getElementById(`${el._id}_${ele._id}`);
            // console.log(ele?.check, cb.checked)
            if (cb.checked) tempRooms.push(ele._id);
          });
        } else {
          tempRooms = [];
        }
        const obj = {
          room_amenity: el._id,
          active: tempRooms?.length ? true : false,
          rooms: tempRooms,
        };
        // console.log("obj: ", obj);
        // if (i == 0) console.log(mappindOldAmenity);
        if (
          el?.oldAmenity &&
          (el?.allrooms !== mappindOldAmenity?.allrooms ||
            (el?.somerooms !== mappindOldAmenity?.somerooms && tempRooms?.length) ||
            el?.noroom !== mappindOldAmenity?.noroom)
        )
          // self.confirmSave(obj);
          tmpArray.push({
            room_amenity_id: el._id,
            rooms: tempRooms,
            active: tempRooms?.length ? true : false,
          });
        else if (
          !el?.oldAmenity &&
          (el.allrooms || (el.somerooms && tempRooms?.length))
        ) {
          obj.active = true;
          self.addNewAmenityFunc(obj);
        }
      });
      self.confirmSave(tmpArray);
      setTimeout(() => {
        // self.loadingData = false;
        self.initAll();
      }, 3000);
    },
    async confirmSave(array) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        // const updateobj = {
        //   active: obj.active,
        //   rooms: obj.rooms,
        // };
        const updateArray = array;
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/room_amenity?property_id=${self.propertyID}`,
          updateArray,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );

        if (res.status == 200) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
        // console.log("confirmSave: ", res);
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.loading = false;
        self.cansaveroomamenity = true;
        EventBus.$emit("endloading");
      }
    },
    async addNewAmenityFunc(obj) {
      const self = this;
      // console.log(self.addNewAmenity);
      // const obj = {
      //   room_amenity: self?.addNewAmenity || "",
      //   active: true,
      //   rooms: []
      // };
      // if (self?.addNewAmenity) {
      // console.log("create: ", obj);
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/room_amenity?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res?.status == 200) {
          self.addNewAmenity = "";
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.loading = false;
        self.cansaveroomamenity = true;
        EventBus.$emit("endloading");
      }
      // }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    changeAllRooms(item) {
      const self = this;
      var temp = [];
      item.allrooms = true;
      item.somerooms = false;
      item.noroom = false;
      item?.rooms?.map((el) => {
        temp.push({
          ...el,
          check: true,
        });
      });
      item.rooms = temp;
      // console.log(item);
    },
    changeSomeRooms(item) {
      const self = this;
      item.allrooms = false;
      item.somerooms = true;
      item.noroom = false;
      // console.log(item);
    },
    changeNoRoom(item) {
      const self = this;
      var temp = [];
      item.allrooms = false;
      item.somerooms = false;
      item.noroom = true;
      item?.rooms?.map((el) => {
        temp.push({
          ...el,
          check: false,
        });
      });
      item.rooms = temp;
      // console.log(item);
    },
    checkChangeData() {
      const self = this;
      var cantleavethispage = false;

      self.itemRoomAmenity?.map((el) => {
        const temp = self.itemOldRoomAmenity?.find((p) => p?._id == el?._id);
        // console.log(temp, self.itemOldRoomAmenity, self.itemRoomAmenity);
        if (
          temp?.allrooms == el?.allrooms &&
          temp?.somerooms == el?.somerooms &&
          temp?.noroom == el?.noroom
        ) {
          el?.rooms?.map((ele) => {
            const tempRoom = temp?.rooms?.find((p) => p?._id == ele?._id);
            if (tempRoom?.check !== ele?.check) {
              cantleavethispage = true;
            }
          });
        }
        // else if (temp?.somerooms !== el?.somerooms) {
        //   el?.rooms?.map(ele => {
        //     const tempRoom = temp?.rooms?.find(p => p?._id == ele?._id);
        //     console.log(tempRoom, ele)
        //     if (tempRoom?.check !== ele?.check) {
        //       cantleavethispage = true;
        //     }
        //   });
        // }
        else if (
          temp?.allrooms !== el?.allrooms ||
          temp?.somerooms !== el?.somerooms ||
          temp?.noroom !== el?.noroom
        ) {
          el?.rooms?.map((ele) => {
            const tempRoom = temp?.rooms?.find((p) => p?._id == ele?._id);
            if (tempRoom?.check !== ele?.check) {
              cantleavethispage = true;
            }
          });
        }
        // else cantleavethispage = true;
      });
      return cantleavethispage;
    },
  },
};
</script>

<style>
.v-label {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

/* The container */
.container-cb {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-cb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-cb:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-cb input:checked ~ .checkmark {
  background-color: #666ee8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-cb:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-cb input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-cb .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  color: rgba(19, 1, 1, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.7) !important;
}

.btn-allrooms-left {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-left-radius: 0px;
  background-color: #fff !important;
  color: #666ee8 !important;
}

.btn-allrooms-left-active {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-left-radius: 0px;
  background-color: #666ee8 !important;
  color: #fff !important;
}

.btn-allrooms-right {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #fff !important;
  color: #666ee8 !important;
}

.btn-allrooms-right-active {
  border: 1px solid #666ee8;
  max-width: fit-content;
  border-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #666ee8 !important;
  color: #fff !important;
}

.radio-group input[type="radio"]:checked + label:after,
.checkbox-group input[type="checkbox"]:checked + label:after {
  content: "";
  color: #ea6f21;
  background-color: #1e9ff2 !important;
  position: absolute;
  left: 1px;
  top: 1px;
  right: 0px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.checkbox-group input[type="checkbox"]:checked + label:after {
  background-repeat: no-repeat;
  background-image: url(../../../../assets/icons/icon-checkmark-white.svg);
  background-position: center center;
  background-size: 10px;
}

.checkbox-group input[type="checkbox"]:checked + label:after {
  border-radius: 3px;
}

.radio-group input[type="radio"]:checked + label:before,
.checkbox-group input[type="checkbox"]:checked + label:before {
  background: #1e9ff2 !important;
}
</style>
